<template>
  <div class="container">
    <!-- 页面标题 -->
    <header>R6S 3D地图</header>
    <!-- 地图筛选 -->
    <div class="filter">
      <div
        class="filter-item"
        :class="currentFilter == 'team-deathmatch' ? 'active' : ''"
        @click="currentFilter = 'team-deathmatch'"
      >
        死斗
      </div>
      <div
        class="filter-item"
        :class="currentFilter == 'quick-match' ? 'active' : ''"
        @click="currentFilter = 'quick-match'"
      >
        快速
      </div>
      <div
        class="filter-item"
        :class="currentFilter == 'new-comer' ? 'active' : ''"
        @click="currentFilter = 'new-comer'"
      >
        新进
      </div>
      <div
        class="filter-item"
        :class="currentFilter == 'ranked' ? 'active' : ''"
        @click="currentFilter = 'ranked'"
      >
        排位
      </div>
      <div class="filter-item clear" @click="currentFilter = 'all'">
        <span></span>清除
      </div>
    </div>
    <!-- 地图列表 -->
    <div class="list">
      <div
        class="list-item"
        v-for="item in mapList"
        :key="item.id"
        @click="$router.push(`/viewer/${item.id}`)"
        v-show="
          item.type.indexOf(currentFilter) != -1 || currentFilter == 'all'
        "
      >
        <img
          class="list-item__cover"
          :src="`/images/cover/${item.id}.jpg`"
          :alt="item.name"
        />
        <div class="list-item__name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectMap",
  data() {
    return {
      currentFilter: "all",
      mapList: [
        {
          id: "emeraldplains",
          name: "翡翠园",
          type: ["quick-match", "ranked", "new-comer", "team-deathmatch"],
        },
        {
          id: "plane",
          name: "总统专机",
          type: ["quick-match"],
        },
        {
          id: "themepark",
          name: "主题乐园",
          type: ["quick-match", "ranked", "team-deathmatch"],
        },
        {
          id: "villa",
          name: "庄园",
          type: ["quick-match", "ranked", "team-deathmatch"],
        },
        {
          id: "fortress",
          name: "要塞",
          type: ["quick-match"],
        },
        {
          id: "kanal",
          name: "运河",
          type: ["quick-match", "ranked"],
        },
        {
          id: "yacht",
          name: "游艇",
          type: ["quick-match"],
        },
        {
          id: "house",
          name: "芝加哥豪宅",
          type: ["quick-match"],
        },
        {
          id: "bank",
          name: "银行",
          type: ["quick-match", "ranked"],
        },
        {
          id: "skyscraper",
          name: "摩天大楼",
          type: ["quick-match", "ranked", "team-deathmatch"],
        },
        {
          id: "favela",
          name: "贫民窟",
          type: ["quick-match", "ranked", "team-deathmatch"],
        },
        {
          id: "chalet",
          name: "木屋",
          type: ["quick-match", "ranked", "new-comer"],
        },
        {
          id: "consulate",
          name: "领事馆",
          type: ["quick-match", "ranked"],
        },
        {
          id: "tower",
          name: "塔楼",
          type: ["quick-match"],
        },
        {
          id: "border",
          name: "边境",
          type: ["quick-match", "ranked"],
        },
        {
          id: "hereford",
          name: "赫里福基地",
          type: ["quick-match"],
        },
        {
          id: "outback",
          name: "荒漠服务站",
          type: ["quick-match", "ranked", "new-comer"],
        },
        {
          id: "oregon",
          name: "俄勒冈乡间屋宅",
          type: ["quick-match", "ranked", "new-comer"],
        },
        {
          id: "coastline",
          name: "海岸线",
          type: ["quick-match", "ranked", "new-comer", "team-deathmatch"],
        },
        {
          id: "clubhouse",
          name: "俱乐部",
          type: ["quick-match", "ranked", "new-comer"],
        },
        {
          id: "kafe",
          name: "咖啡馆",
          type: ["quick-match", "ranked", "new-comer"],
        },
      ],
    };
  },
};
</script>
<style lang="scss">
body {
  background: #212e50 url("~@/assets/bg.jpg") 50% 50% repeat-y;
  background-size: 100%;
  user-select: none;
}
.container {
  width: 100%;
  min-height: 100%;
  text-align: center;
  background: url("~@/assets/tr.png") 100% 0 no-repeat,
    url("~@/assets/tl.png") 0 0 no-repeat,
    url("~@/assets/br.png") 100% 100% no-repeat,
    url("~@/assets/bl.png") 0 100% no-repeat;
}
header {
  color: #fff;
  font-size: 3em;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 20px;
}
.filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #fff;
  margin-bottom: 40px;
  &-item {
    padding: 5px 20px;
    margin: 5px;
    border: solid 1px #fff;
    cursor: pointer;
    transition: background-color 0.15s ease-in, border-color 0.15s ease-in,
      color 0.15s ease-in;
    &:hover {
      background-color: hsla(0, 0%, 100%, 0.5);
      color: #24262a;
    }
    &.active {
      background-color: #00ffe5;
      border-color: #00ffe5;
      color: #24262a;
      cursor: auto;
    }
    &.clear {
      background-color: #fff;
      color: #24262a;
      span {
        display: inline-block;
        margin: -1px 5px 0 0;
        width: 10px;
        height: 10px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%2324262a' d='M32 2l-2-2-14 14L2 0 0 2l14 14L0 30l2 2 14-14 14 14 2-2-14-14z'/%3E%3C/svg%3E")
          50% no-repeat;
      }
      &:hover {
        background-color: hsla(0, 0%, 100%, 0.75);
      }
    }
  }
}
.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  &-item {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    &__cover {
      width: 100%;
      aspect-ratio: 16 / 9;
    }
    &__name {
      background-color: #c3c3c3;
      color: #24262a;
      font-size: 1.2em;
      padding: 10px;
      font-weight: bold;
      font-style: italic;
    }
    &:hover {
      transform: scale(1.15);
      box-shadow: 0 0 10px 1px rgb(0 0 0 / 50%);
      z-index: 999;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba(0, 255, 230, 0.5),
          rgba(0, 255, 230, 0) 50%
        );
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    padding: 20px;
  }
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  .container {
    padding: 20px 40px 40px 40px;
  }
  .list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 993px) {
  .container {
    padding: 20px 120px 120px 120px;
  }
  .list {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
